import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const CONTACT_MAIL = "betbiri.info@gmail.com"

const Mail = ({ mail = CONTACT_MAIL }) => (
  <OutboundLink href={`mailto:${mail}`}>{mail}</OutboundLink>
)

export default Mail
