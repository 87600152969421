import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const LIST_TO_GRID_THRESHOLD = 4

const Links = ({ data, center }) => {
  if (!data || !data.length > 0) return null

  let listClasses = `lg:max-w-xl space-y-4`
  if (center) listClasses += ` lg:mx-auto lg:text-center`
  if (data.length > LIST_TO_GRID_THRESHOLD)
    listClasses = `grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6`

  return (
    <ul className={listClasses}>
      {data.map(({ title, to }) => (
        <li
          key={title}
          className="flex transition-colors duration-150 ease-out shadow hover:bg-gray-100"
        >
          <Link to={to} className="flex flex-col justify-between flex-1 p-4">
            <span>{title}</span>
            <span className="text-sm font-semibold">Lees meer...</span>
          </Link>
        </li>
      ))}
    </ul>
  )
}

Links.propTypes = {
  center: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, to: PropTypes.string })
  ),
}

Links.defaultProps = {
  center: false,
}

export default Links
