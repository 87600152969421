import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Links from "../components/links"
import Mail from "../components/mail"

const links = [
  {
    title: "1. Hair Reduction",
    to: "/info/vpl-light-treatment",
  },
  { title: "2. Anti Aging", to: "/info/vpl-light-treatment/anti-aging" },
  {
    title: "3. Pigmentations Removal",
    to: "/info/vpl-light-treatment/pigmentations-removal",
  },
  {
    title: "4. Vancé",
    to: "/info/vpl-light-treatment/vance",
  },
  {
    title: "5. Couperose - rostra",
    to: "/info/vpl-light-treatment/couperose-rostra",
  },
  {
    title: "6. Acné",
    to: "/info/vpl-light-treatment/acne",
  },
]

const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <Section>
      <article className="prose lg:prose-xl">
        <h1>Contacteer ons</h1>

        <p>
          Heb je interesse? Wil je meer weten? Wil je een afspraak maken? <br />
          Laat ons dan iets weten!
        </p>

        <p>
          Spreek er over op een gratis gesprek of mail je vraag gewoon
          naar&nbsp;
          <Mail /> en we nemen zo snel mogelijk contact op met je!
        </p>
      </article>
    </Section>

    <Section full background="bg-gradient-to-r from-gray-100 to-white">
      <article className="prose lg:prose-xl">
        <h2>Hier nog eens een overzicht van wat wij doen</h2>
      </article>

      <div className="mt-8">
        <Links data={links} />
      </div>
    </Section>
  </Layout>
)

export default Contact
